import { Card, Icon } from 'semantic-ui-react';

function ErrorList({ errors }) {
    const style = {
        color: 'red',
        marginTop: 0,
        marginBottom: 0,
        textAlign: 'center',
        listStyle: 'none',
        marginLeft: 0,
        marginRight: 0
    };
    if (errors) {
        return (
            <Card fluid style={style}>
                <Card.Content>
                    <Card.Header>
                        <Icon size='mini' color='red' style={{ fontSize: '2em' }} name='exclamation circle' />
                    </Card.Header>
                </Card.Content>
                <Card.Content>
                    <ul style={{ listStyle: 'none' }}>
                        {errors.map(error => <li style={{ margin: 5 }} key={error}>{error}</li>)}
                    </ul>
                </Card.Content>
            </Card>
        );
    }
    return <div></div>;
}

export default ErrorList;
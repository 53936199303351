import { createContext, useState } from 'react';

const QuestionnaireContext = createContext();

function QuestionnaireProvider({ children }) {
    const [questionnaire, setQuestionnaire] = useState({});
    const [questionnaireResponse, setQuestionnaireResponse] = useState();
    const [formWithUserData, setFormWithUserData] = useState();

    return (
        <QuestionnaireContext.Provider value={{ questionnaire, setQuestionnaire, questionnaireResponse, setQuestionnaireResponse, formWithUserData, setFormWithUserData }}>
            {children}
        </QuestionnaireContext.Provider>
    );
}

export { QuestionnaireContext, QuestionnaireProvider };
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import { ModalProvider } from "./contexts/modalContext";
import { LanguageProvider } from "./contexts/languageContext";
import { ConfigProvider } from "./contexts/configContext";
import { FhirClientProvider } from "react-fhirclient";
import { LaunchProvider } from "./contexts/launchContext";
import { QuestionnaireProvider } from "./contexts/questionnaireContext";

console.log("start app");
ReactDOM.render(
  <React.StrictMode>
    <FhirClientProvider>
      <LaunchProvider>
        <ConfigProvider>
          <QuestionnaireProvider>
            <LanguageProvider>
              <ModalProvider>
                <App />
              </ModalProvider>
            </LanguageProvider>
          </QuestionnaireProvider>
        </ConfigProvider>
      </LaunchProvider>
    </FhirClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

const https = require('https');
/*
const options = {
    hostname: 'www.random.org',
    method: 'GET',
    connectTimeout: 1000
};
*/
export function request(options) {
    return new Promise((resolve, reject) => {
        const req = https.request(options);
        const startTime = Date.now();

        const connectTimeout = options.connectTimeout || 3000;
        const body = options.body || {};

        const connectTimer = setTimeout(() => {
            const error = new Error('Connection timeout');
            error.code = 'ETIMEDOUT';
            req.emit('error', error);
            req.destroy();
        }, connectTimeout);

        req.once('socket', (socket) => {
          console.log(`socket ${Date.now() - startTime}ms`);
            socket.once('connect', () => {
              console.log(`connect ${Date.now() - startTime}ms`);
              clearTimeout(connectTimer);
            });
        });        
        
        req.once('response', (response) => {   
            response.setEncoding('utf-8');  
            clearTimeout(connectTimer);
            console.log(`response ${Date.now() - startTime}ms`);            
            let responseBody = [];
            response.on('data', data => {
                //console.log('data', data);
                responseBody += data;
            });         

            response.on('end', () => {
                console.log('end');
                resolve({ response, responseBody });
            });
        });

        req.once('error', error => {
            console.log(`error ${Date.now() - startTime}ms`);
            console.log(error);
            clearTimeout(connectTimer);          
            reject(error);
        });

        if (options.method.toUpperCase() === 'POST' || options.method.toUpperCase() === 'PUT') {
            req.write(body);
        }
        
        req.end();
    });  
}

export function retryableRequest(options) {
    const maxRetries = options.maxRetries || 0;
    options.retryCount = 0;

    async function retry() {
        console.log(`retry ${options.retryCount}`);
        try {
            return await request(options);
        }
        catch (err) {
            console.log('retryableRequest error', err);
            if (err.code === 'ETIMEDOUT') {
                console.log(`retry ${options.retryCount} - Connection timeout`);
                if (options.retryCount < maxRetries) {
                    options.retryCount++;
                    return await retry(options);
                }
            }
            throw err;
        }
    }

    return retry();
}
import { useContext } from 'react';
import { Modal, Button, Icon } from 'semantic-ui-react';
import { ModalContext } from '../../contexts/modalContext';

function FFModal() {
  const { modalType, modalTitle, modalMessage, modalConfirmAction, modalCancelAction, modalConfirmText, modalCancelText, modalIsOpen, closeModal, afterOpenModal, closeOnDimmerClick } = useContext(ModalContext);

  function renderHeader() {
    console.log('Rendering Header');
    if (modalType == 'error') {
      return <Icon color='red' size='mini' style={{ fontSize: '2em' }} name='exclamation circle' />;
    } 

    if (modalType == 'info') {
      return  <Icon color='green' size='mini' style={{ fontSize: '2em' }} name='check circle' />;
    } 

    if (modalType == 'confirm') {
      return <Icon color='green' size='mini' style={{ fontSize: '2em' }} name='question circle' />;
    } 

    return null;
  }

  function renderContent() {
    console.log('Rendering Content', modalTitle, modalMessage);
    return (
          <div>
              {modalTitle ? <div>{modalTitle}</div> : null}
              {modalMessage ? <div>{modalMessage}</div> : null}
          </div>
      );
  }

  function renderAction() {
    console.log('Rendering Action');
    if (modalType == 'error') {
      return <Button color='green' content={'OK'} onClick={() => closeModal()} />;
    } 

    if (modalType == 'info') {
      return <Button color='green' content={'OK'} onClick={() => closeModal()} />;
    } 

    if (modalType == 'confirm') {
      return (
        <Modal.Actions style={{ display: 'flow-root' }}>

            <Button
                style={{ float: 'left', minWidth: 120 }}
                color='green'
                onClick={() => modalConfirmAction()}>
                <Icon name='checkmark' />{modalConfirmText}
            </Button>

            <Button style={{ float: 'right', minWidth: 120, color: '#fff' }}
                color='red'
                onClick={() => modalCancelAction()}>
                <Icon name='close' inverted />
                {modalCancelText}
            </Button>

        </Modal.Actions>
      );
    } 

    return null;
  }

  return (
    <Modal
      open={modalIsOpen}
      onClose={closeModal}
      onOpen={afterOpenModal}
      dimmer='inverted'
      size='mini'
      style={{ position: 'static' }}
      closeOnDimmerClick={closeOnDimmerClick}
    >

      <Modal.Header style={{ textAlign: 'center' }}>
        {renderHeader()}
      </Modal.Header>

      <Modal.Content style={{ textAlign: 'center' }}>
        {renderContent()}
      </Modal.Content>

      <Modal.Actions style={{ textAlign: 'center' }}>
        {renderAction()}
      </Modal.Actions>
    </Modal>
  );
}

export default FFModal;
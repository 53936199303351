import { Container, Menu, Grid } from 'semantic-ui-react';
import './Footer.css';

function Footer({ leftContent, rightContent }) {
    return (
        <div >
            <Menu stackable className='footer' inverted borderless={true} color='green'>
                <Container>
                    <Grid >
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <Menu.Item className='content'>
                                    {leftContent}
                                </Menu.Item>
                            </Grid.Column>
                            <Grid.Column>
                                <Menu.Item className='content content-right'>
                                    {rightContent}
                                </Menu.Item>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>
            </Menu>
        </div>
    );
}

export default Footer;
/*
function Footer({ language }) {

    function renderFooter() {
        if (language === 'fr') {
            return (
                <Container>
                    <Menu.Item style={{ whiteSpace: 'pre', fontSize: 12 }}>
                    Pour plus d'informations sur la réglementation relative aux tests antigéniques rapides en pharmacie,
   <a style={{ textDecoration: 'underline', fontSize: 12 }} target='_blank' href='http://www.ejustice.just.fgov.be/eli/arrete/2021/07/05/2021021299/moniteur'>cliquez ici.</a>
                    </Menu.Item>
                    <Menu.Item floated='right' style={{ whiteSpace: 'pre', fontSize: 12, textAlign: 'right' }}>
                    Vous cherchez la Politique générale de Confidentialité ou le DPIA de FarmaFlux? <a style={{ textDecoration: 'underline', fontSize: 12 }} target='_blank' href='https://www.farmaflux.be/fr/farmaflux-mene-une-politique-rigoureuse-en-matiere-de-respect-de-la-vie-privee/'>Cliquez ici.</a>
                    </Menu.Item>
                </Container>
            );
        }
        return (
            <Container>
                    <Menu.Item style={{ whiteSpace: 'pre', fontSize: 12 }}>
                    Voor meer informatie wenst over de reglementering in verband met de snelle antigeentesten in de apotheek, <a style={{ textDecoration: 'underline', fontSize: 12 }} target='_blank' href='http://www.ejustice.just.fgov.be/eli/besluit/2021/07/05/2021021299/staatsblad'>klik hier.</a>
                    </Menu.Item>
                    <Menu.Item floated='right' style={{ whiteSpace: 'pre', fontSize: 12, textAlign: 'right' }}>
                    Bent u op zoek naar de Algemene Privacy Policy of de DPIA van FarmaFlux? <a style={{ textDecoration: 'underline', fontSize: 12 }} target='_blank' href='https://www.farmaflux.be/farmaflux-hanteert-een-strikt-privacybeleid/'>Klik hier.</a>
                                        </Menu.Item>
                </Container>
        );
    }

    return (        
            <Menu stackable style={{ bottom: 0, width: '100%', minHeight: '60px' }} inverted borderless={true} color='green'>
                {renderFooter()}
            </Menu>        
    );
}

export default Footer;
*/
import { Container, Menu, Image } from 'semantic-ui-react';
import './Navbar.css';

function Navbar({ title, image }) {
    return (
    <Menu className='navbar' color='green' inverted borderless={true}>
      <Container>
        <Menu.Item header className='header'>
          <Image src={image} size='tiny' className='image'/>
          {title}
        </Menu.Item>
      </Container>
    </Menu>
    );
}

export default Navbar;
import { useState, useContext } from 'react';
import { Button, Grid, Icon } from 'semantic-ui-react';
import { useFhirClient } from 'react-fhirclient';
import { LaunchContext } from '../../../contexts/launchContext';
import { QuestionnaireContext } from '../../../contexts/questionnaireContext';
import { LanguageContext } from '../../../contexts/languageContext';
import { ModalContext } from '../../../contexts/modalContext';
import useIsSessionAlive from '../../../hooks/useIsSessionAlive';
import { retryableRequest } from '../../../request';
import languageMap from '../../../languageMap';
import { ConfigContext } from '../../../contexts/configContext';

function AddActionButton({ action }) {
    const [active, setActive] = useState(false);
    const [loading, setLoading] = useState(false);
    const client = useFhirClient();
    const isAlive = useIsSessionAlive();
    const { dguid, ehr } = useContext(LaunchContext);    
    const { language } = useContext(LanguageContext);
    const { setModalContent, setModalHeader, openModal, closeModal, setModalAction } = useContext(ModalContext);
    const { config } = useContext(ConfigContext);
    console.log('action', action);
       
    function showErrorModal({ title, message }) {
        const header = <Icon color='red' size='mini' style={{ fontSize: '2em' }} name='exclamation circle' />;
        const content = (
          <div>
            {title ? <div>{title}</div> : null}
            {message ? <div>{message}</div> : null}
          </div>
        );
        setModalHeader(header);
        setModalContent(content);
        setModalAction(<Button color='green' content={'OK'} onClick={closeModal} />);
        openModal();
    }

    async function submit() {
        try {
            if (!client) {
                throw new Error(language ? languageMap['noSession'][language] : 'There is no active session. Please relaunch the app.');
            }
            if (!isAlive) {
                throw new Error(language ? languageMap['sessionExpired'][language] : 'Session has expired. Please relaunch the app.');
            }                     

            const requestBody = {
                'QuestionnaireResponse': dguid,
                'action': action.context
              };

            const options = {
                host: `${ehr.split('https://')[1]}`,
                path: '/idppharmacylaunch',
                method: 'POST',
                connectTimeout: 3000,
                headers: {
                  'Content-Type': 'application/json',
                  'Accept': 'application/json',
                  'Authorization': `Bearer ${client.state.tokenResponse.access_token}` 
                },
                maxRetries: 3,
                body: JSON.stringify(requestBody)
              };

            let response;
            let data;
            try {
                console.log('making request', options);
                const res = await retryableRequest(options);
                response = res.response;
                if (res.responseBody && typeof res.responseBody === 'string') {
                    data = JSON.parse(res.responseBody);
                }
            } catch (err) {
                throw new Error(err.message);
            }

            console.log('data', data);
            console.log('response', response);

            if (response.statusCode !== 200 && response.statusCode !== 201) {
                console.log('status', response.statusCode);

                if (data.issue && data.issue.length) {
                    throw new Error(data.issue[0].diagnostics);
                }
                else {
                    throw new Error(`HTTP Error ${response.statusCode} - ${response.statusMessage}`);
                }
            }
            let issues = [];
            if (data.issue && data.issue.length > 0) {
                data.issue.forEach(issue => {
                    console.log(issue);
                    issues.push(issue.diagnostics);
                });
            }
            setLoading(false);
           // setFormIsSubmitted(true);            
            
            let content;
            if (issues.length === 0 && config && config.formHasBeenSubmitted) {
                content = config.formHasBeenSubmitted[language];
            } else if (issues.length > 0) {

                content = <ul>{issues.map((issue, index) => <li style={{ textAlign: 'left', marginBottom: 5 }} key={`issue-${index}`}>{issue}</li>)}</ul>;
            }
            window.location.replace(data.launchurl);

        } catch (err) {
            setLoading(false);
            showErrorModal({ title: language ? languageMap['errorHasOccured'][language] : 'An error has occured', message: err.message });
            console.error(err);
        }
    }

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    {action.helptext}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Button style={{ width: '100%' }} color='blue' fluid active={active} loading={loading} onClick={() => submit()}>
                        {action.buttontext}
                    </Button>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default AddActionButton;
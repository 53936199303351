import { useContext } from 'react';

import { Card } from 'semantic-ui-react';
import AddActionButton from "./AddActionButton/AddActionButton";
import { LanguageContext } from '../../contexts/languageContext';
import languageMap from '../../languageMap';

const actionMap = {
    addActionButton: AddActionButton
} 

function Actions({ actions }) {
    const { language } = useContext(LanguageContext);       

    function renderActions() {
        let actionsArray = [];

        actions.forEach(action => {
            if (action.action in actionMap) {
                actionsArray.push(actionMap[action.action]({action}));            
            }
        }); 

        if (actionsArray.length) {
            return (
                <Card fluid>
                    <Card.Content>
                        <Card.Header>
                            {language ? languageMap['actions'][language] : 'Actions'}
                        </Card.Header>
                    </Card.Content>                
                    <Card.Content>
                        <ul style={{ listStyle: 'none' }}>
                            {actionsArray.map((action, i) => <li style={{ margin: 5 }} key={`action-${i}`}>{action}</li>)}
                        </ul>
                    </Card.Content>
                </Card>
            );
        }
        return null;
    }

    return renderActions();    
}

export default Actions;
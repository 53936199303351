const languageMap = {
  submit: {
    fr: "envoyer",
    nl: "verzenden",
  },
  save: {
    fr: "enregistrer",
    nl: "opslaan",
  },
  formHasBeenSaved: {
    fr: "Le questionnaire a été enregistré",
    nl: "De form is opgeslagen",
  },
  formHasBeenSubmitted: {
    fr: "Ce e-Form a été correctement envoyé à Farmaflux! Farmaflux enverra ce e-Form à Sciensano afin de créer le certificat. Cliquez sur ‘OK’ et fermez cette fenêtre.",
    nl: "Deze eForm is correct verzonden naar Farmaflux! Farmaflux stuurt deze eForm door naar Sciensano voor de aanmaak van het certificaat. Klik op ‘OK’ en sluit dit venster.",
  },
  invalidForm: {
    fr: "Le questionnaire n'est pas rempli correctement",
    nl: "De form is niet correct ingevuld",
  },
  errorHasOccured: {
    fr: "Une erreur s'est produite",
    nl: "Een fout heeft zich voorgedaan",
  },
  errorListTitle: {
    fr: "Erreurs",
    nl: "fouten",
  },
  noChange: {
    fr: "Aucun changement a été détecté",
    nl: "Er is geen verandering gedetecteerd",
  },
  sessionExpired: {
    fr: "La session est expirée. Veuillez relancer l'application",
    nl: "De sessie is verlopen. Gelieve de applicatie te herstarten",
  },
  noSession: {
    fr: "Aucune session est active. Veuillez relancer l'application",
    nl: "Er is geen actieve sessie gevonden. Gelieve de applicatie te herstarten",
  },
  confirm: {
    fr: "Confirmer",
    nl: "Bevestig",
  },
  checkResult: {
    fr: "Etes-vous certain du résultat du test = détecté? Ceci ne peut plus être changé. Le patient sera contacté par le contact tracing en ne recevra pas de Certificat Covid.",
    nl: "Bent u zeker van het testresultaat = gedectecteerd? Dit kan niet meer worden veranderd. De patiënt zal gecontacteerd worden door contact tracing en zal geen Covid Certificaat ontvangen",
  },
  yes: {
    fr: "Oui",
    nl: "Ja",
  },
  no: {
    fr: "Non",
    nl: "Nee",
  },
  actions: {
    fr: "Actions",
    nl: "Acties",
  },
};

export default languageMap;

import { createContext, useState } from 'react';

const LaunchContext = createContext();

function LaunchProvider({ children }) {
    const [dguid, setDguid] = useState();
    const [mode] = useState(sessionStorage.getItem('mode'));
    const [ehr] = useState(sessionStorage.getItem('ehr'));
    const [iss] = useState(sessionStorage.getItem('iss'));
    const [launch] = useState(sessionStorage.getItem('launch'));

    return (
        <LaunchContext.Provider value={{ dguid, setDguid, mode, ehr, iss, launch }}>
            {children}
        </LaunchContext.Provider>
    );
}

export { LaunchContext, LaunchProvider };
import { useFhirClient } from "react-fhirclient";
import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Container,
  Card,
  Icon,
  Loader,
  Modal,
} from "semantic-ui-react";
import Navbar from "./components/Navbar/Navbar";
import ErrorList from "./components/ErrorList/ErrorList";
import Questionnaire from "./components/Questionnair/Questionnair";
import Footer from "./components/Footer/Footer";
import Actions from "./components/Actions/Actions";
import useIsSessionAlive from "./hooks/useIsSessionAlive";
import "semantic-ui-css/semantic.min.css";
import languageMap from "./languageMap";
import { retryableRequest } from "./request";
import MessageBox from "./components/MessageBox/MessageBox";
import { ModalContext } from "./contexts/modalContext";
import { LanguageContext } from "./contexts/languageContext";
import { ConfigContext } from "./contexts/configContext";
import parseJwt from "./parseJwt";
import { Parser as HtmlToReactParser } from "html-to-react";
import decodeUnicode from "./decodeUnicode";
import _ from "lodash";
import { LaunchContext } from "./contexts/launchContext";
import { QuestionnaireContext } from "./contexts/questionnaireContext";
import LForms from './lforms';

function App() {
  console.log("start app.js");

  const { closeModal, showConfirmModal, showInfoModal, showErrorModal } =
    useContext(ModalContext);
  const { dguid, setDguid, ehr, iss, launch, mode } = useContext(LaunchContext);
  const {
    questionnaire,
    setQuestionnaire,
    questionnaireResponse,
    setQuestionnaireResponse,
    formWithUserData,
    setFormWithUserData,
  } = useContext(QuestionnaireContext);
  const { language, setLanguage } = useContext(LanguageContext);
  const { config, setConfig } = useContext(ConfigContext);
  const [formIsSubmitted, setFormIsSubmitted] = useState(false);
  const [formHasLoaded, setFormHasLoaded] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const [orgQuestionnaireResponseItem, setOrgQuestionnaireResponseItem] =
    useState();
  const [actions, setActions] = useState([]);
  const [loading, setLoading] = useState(false);
  const htmlToReactParser = new HtmlToReactParser();
  const client = useFhirClient();
  const isAlive = useIsSessionAlive();

  async function fetchData() {
    console.log("fetching PrefillQuestionnaireResponse...");

    console.log(client);
    const bearer = client.state.tokenResponse.access_token;
    const jsonPayload = parseJwt(bearer);
    setLanguage(jsonPayload.language.toLowerCase());
    console.log("claims:" + jsonPayload);

    const options = {
      host: `${ehr.split("https://")[1]}`,
      path: `/getLaunchInfo?launch=${launch}`,
      method: "GET",
      connectTimeout: 3000,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${bearer}`,
      },
      maxRetries: 3,
    };

    let response;
    let data;

    const res = await retryableRequest(options);
    console.log("res", res);
    response = res.response;

    data = JSON.parse(res.responseBody);
    if (response.statusCode !== 200 && response.statusCode !== 201) {
      console.log("status", response.statusCode);

      if (data.issue && data.issue.length) {
        throw new Error(data.issue[0].diagnostics);
      } else {
        throw new Error(
          `HTTP Error ${response.statusCode} - ${response.statusMessage}`
        );
      }
    }

    if (data.launchinfo) {
      console.log("data", data);

      const dguid = data.launchinfo.launchinfo.dguid;
      setDguid(dguid);

      setQuestionnaire(data.launchinfo.launchinfo.QuestionnaireJson);
      setQuestionnaireResponse(
        data.launchinfo.launchinfo.QuestionnaireResponseJson
      );
      setOrgQuestionnaireResponseItem(
        data.launchinfo.launchinfo.QuestionnaireResponseJson.item
      );
      //questionnaireId = data.launchinfo.launchinfo.Questionnaire;
      setFormIsSubmitted(
        data.launchinfo.launchinfo.QuestionnaireResponseJson.status !==
          "in-progress"
          ? true
          : false
      );

      if (data.launchinfo.launchinfo.config) {
        setConfig(data.launchinfo.launchinfo.config.config);
      }

      if (
        data.launchinfo.launchinfo.QuestionnaireResponseJson.extension.length >
        1
      ) {
        let newActions = [];
        data.launchinfo.launchinfo.QuestionnaireResponseJson.extension.forEach(
          (extension) => {
            if (
              extension.url ===
              "https://www.gfd-dpp.be/fhir/questionnairemetadata/base64"
            ) {
              newActions.push(JSON.parse(decodeUnicode(extension.valueString)));
            }
          }
        );
        setActions(newActions);
      }

      console.log(
        "config",
        JSON.stringify(data.launchinfo.launchinfo.config),
        JSON.stringify(config)
      );
    }
  }

  useEffect(() => {
    try {
      if (!sessionStorage.getItem("SMART_KEY")) {
        throw new Error(
          language
            ? languageMap["noSession"][language]
            : "There is no active session. Please relaunch the app."
        );
      }
      if (client) {
        fetchData();
      }
    } catch (err) {
      showErrorModal({
        title: language
          ? languageMap["errorHasOccured"][language]
          : "An error has occured",
        message: err.message,
      });
      console.error(err);
    }
  }, [client]);

  useEffect(() => {
    console.log("loading form");
    try {
      if (questionnaire && questionnaireResponse && config) {
        console.log("start convertFHIRQuestionnaireToLForms");
        console.log(questionnaire, questionnaireResponse, config);

        console.log(
          "start QuestionnaireJson:" + JSON.stringify(questionnaire, null, 0)
        );

        const lformsQ =
          LForms.FHIR.R4.SDC.convertQuestionnaireToLForms(questionnaire);

        function recurse({ formData, questionnaire }) {
          function iterate(formDataNode, questionnaireNode) {
            if (Array.isArray(questionnaireNode)) {
              questionnaireNode.forEach((item, index) => {
                if (item.type === "group") {
                  iterate(formDataNode[index].items, item.item);
                }
                if (item.type === "display" && item.item) {
                  item.item.forEach((itemItem) => {
                    if (itemItem.extension) {
                      itemItem.extension.forEach((extension) => {
                        if (
                          extension.valueCodeableConcept &&
                          extension.valueCodeableConcept.coding[0].display ===
                            "textashtml"
                        ) {
                          formDataNode[index].codingInstructionsFormat = "html";
                        }
                      });
                    }
                  });
                }
              });
            }
          }

          iterate(formData.items, questionnaire.item);

          return formData;
        }

        console.log(
          "QuestionnaireResponseJson:" +
            JSON.stringify(questionnaireResponse, null, 0)
        );

        if (mode === "nqr") {
          console.log("mode nqr");
          lformsQ.templateOptions = config.templateOptions;
          LForms.Util.addFormToPage(lformsQ, formContainer);
        } else {
          console.log("mode not nqr");
          let newFormWithUserData = LForms.Util.mergeFHIRDataIntoLForms(
            "QuestionnaireResponse",
            questionnaireResponse,
            lformsQ,
            "R4"
          );

          newFormWithUserData.templateOptions = config.templateOptions;
          newFormWithUserData = recurse({
            formData: newFormWithUserData,
            questionnaire: questionnaire,
          });
          //formWithUserData.items[3].items[0].codingInstructionsFormat = 'html'; to use html in text
          console.log(JSON.stringify(newFormWithUserData));

          setFormWithUserData(newFormWithUserData);
          setFormHasLoaded(true);
        }
      }
    } catch (err) {
      showErrorModal({
        title: language
          ? languageMap["errorHasOccured"][language]
          : "An error has occured",
        message: err.message,
      });
      console.error(err);
    }
  }, [questionnaire, questionnaireResponse, config]);

  useEffect(() => {
    if (formHasLoaded && formWithUserData) {
      console.log("adding form to page");
      LForms.Util.addFormToPage(formWithUserData, "formContainer");
    }
  }, [formHasLoaded, formWithUserData]);

  async function handleSubmit(e) {
    setLoading(true);
    let formFHIRData = LForms.Util.getFormFHIRData(
      "QuestionnaireResponse",
      "R4"
    );

    const errors = LForms.Util.checkValidity(formContainer);
    console.log("errors", errors);
    setValidationErrors(errors);
    if (!errors) {
      console.log("start handleSubmit");

      function checkCode() {
        try {
          if (
            formFHIRData.item[formFHIRData.item.length - 1].item[0].answer[0]
              .valueCoding.code &&
            formFHIRData.item[formFHIRData.item.length - 1].item[0].answer[0]
              .valueCoding.code === "260373001"
          ) {
            return true;
          }
        } catch {
          return false;
        }
        return false;
      }

      // confirm answer with user if needed and submit
      // else just submit
      if (checkCode()) {
        let content = (
          <div>
            {language
              ? languageMap["checkResult"][language]
              : "Are you sure about the result?"}
          </div>
        );
        if (config.config && config.confirmResult) {
          content = config.confirmResult[language];
        }
        showConfirmModal({
          title: language ? languageMap["confirm"][language] : "Confirm",
          message: content,
          confirm: language ? languageMap["yes"][language] : "Yes",
          cancel: language ? languageMap["no"][language] : "No",
          confirmAction: () => submit(),
          cancelAction: () => {
            setLoading(false);
            closeModal();
          },
        });
      } else {
        submit();
      }
    } else {
      setLoading(false);
      showErrorModal({
        title: language
          ? languageMap["invalidForm"][language]
          : "The form is invalid",
        message: (
          <ul style={{ textAlign: "left" }}>
            {errors.map((error) => (
              <li style={{ margin: 5 }} key={error}>
                {error}
              </li>
            ))}
          </ul>
        ),
      });
      console.log(errors);
    }

    async function submit() {
      try {
        if (!client) {
          throw new Error(
            language
              ? languageMap["noSession"][language]
              : "There is no active session. Please relaunch the app."
          );
        }
        if (!isAlive) {
          throw new Error(
            language
              ? languageMap["sessionExpired"][language]
              : "Session has expired. Please relaunch the app."
          );
        }
        let orgQuestionnaireResponseJson = sessionStorage.getItem(
          "QuestionnaireResponseJson"
        );

        console.log(
          "orgQuestionnaireResponseJson:" + orgQuestionnaireResponseJson
        );
        let newQuestionnaireResponse = questionnaireResponse;
        let item = formFHIRData.item;
        newQuestionnaireResponse.item = item;
        newQuestionnaireResponse.status = "completed";
        const bearer = client.state.tokenResponse.access_token;
        console.log("newQuestionnaireResponse", newQuestionnaireResponse);

        const options = {
          hostname: `${
            iss.split("https://")[1]
          }/QuestionnaireResponse/${dguid}`,
          method: "PUT",
          connectTimeout: 5000,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${bearer}`,
          },
          maxRetries: 3,
          body: JSON.stringify(newQuestionnaireResponse),
        };

        let response;
        let data;
        try {
          console.log("making request", options);
          const res = await retryableRequest(options);
          response = res.response;
          if (res.responseBody && typeof res.responseBody === "string") {
            data = JSON.parse(res.responseBody);
          }
        } catch (err) {
          throw new Error(err.message);
        }

        console.log("data", data);
        console.log("response", response);

        if (response.statusCode !== 200 && response.statusCode !== 201) {
          console.log("status", response.statusCode);

          if (data.issue && data.issue.length) {
            throw new Error(data.issue[0].diagnostics);
          } else {
            throw new Error(
              `HTTP Error ${response.statusCode} - ${response.statusMessage}`
            );
          }
        }
        let issues = [];
        if (data.issue && data.issue.length > 0) {
          data.issue.forEach((issue) => {
            console.log(issue);
            issues.push(issue.diagnostics);
          });
        }
        setLoading(false);
        setFormIsSubmitted(true);

        let content = "The form has been submitted";
        if (issues.length === 0 && config && config.formHasBeenSubmitted) {
          content = config.formHasBeenSubmitted[language];
        } else if (issues.length > 0) {
          content = (
            <ul>
              {issues.map((issue, index) => (
                <li
                  style={{ textAlign: "left", marginBottom: 5 }}
                  key={`issue-${index}`}
                >
                  {issue}
                </li>
              ))}
            </ul>
          );
        }
        showInfoModal({ message: content });
      } catch (err) {
        setLoading(false);
        showErrorModal({
          title: language
            ? languageMap["errorHasOccured"][language]
            : "An error has occured",
          message: err.message,
        });
        console.error(err);
      }
    }
  }

  async function handleSave(e) {
    console.log("start handleSave");
    try {
      if (!client) {
        throw new Error(
          language
            ? languageMap["noSession"][language]
            : "There is no active session. Please relaunch the app."
        );
      }
      if (!isAlive) {
        throw new Error(
          language
            ? languageMap["sessionExpired"][language]
            : "Session has expired. Please relaunch the app."
        );
      }
      setLoading(true);

      const formFHIRData = LForms.Util.getFormFHIRData(
        "QuestionnaireResponse",
        "R4"
      );
      const newFormWithUserDataItem = formFHIRData.item;

      if (
        _.differenceWith(
          newFormWithUserDataItem,
          orgQuestionnaireResponseItem,
          _.isEqual
        ).length === 0
      ) {
        console.log("no change");
        setLoading(false);
        showInfoModal({
          message: language
            ? languageMap["formHasBeenSubmitted"][language]
            : "No change has been detected",
        });
      } else {
        let newQuestionnaireResponse = questionnaireResponse;
        newQuestionnaireResponse.item = formFHIRData.item;
        console.log("change detected");
        setOrgQuestionnaireResponseItem(newQuestionnaireResponse.item);
        console.log("saving", newQuestionnaireResponse);
        const bearer = client.state.tokenResponse.access_token;

        const options = {
          hostname: `${
            iss.split("https://")[1]
          }/QuestionnaireResponse/${dguid}`,
          method: "PUT",
          connectTimeout: 3000,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${bearer}`,
          },
          maxRetries: 3,
          body: JSON.stringify(newQuestionnaireResponse),
        };

        let response;
        let data;
        try {
          const res = await retryableRequest(options);
          response = res.response;
          if (res.responseBody && typeof res.responseBody === "string") {
            data = JSON.parse(res.responseBody);
          }
          console.log(data, response);
        } catch (err) {
          throw new Error(err.message);
        }

        console.log("data", data);
        console.log("response", response);

        if (response.statusCode !== 200 && response.statusCode !== 201) {
          console.log(status, response.statusCode);
          //const body = await response.json();

          if (data.issue && data.issue.length) {
            throw new Error(data.issue[0].diagnostics);
          } else {
            throw new Error(
              `HTTP Error ${response.statusCode} - ${response.statusMessage}`
            );
          }
        }

        setLoading(false);
        showInfoModal({
          message: language
            ? languageMap["formHasBeenSaved"][language]
            : "The form has been saved",
        });
      }
    } catch (err) {
      setLoading(false);
      showErrorModal({
        title: language
          ? languageMap["errorHasOccured"][language]
          : "An error has occured",
        message: err.message,
      });
      console.error(err);
    }
  }

  function renderMessage() {
    if (config && config.infoMessage.content && config.infoMessage.content[language]) {
      return (
        <MessageBox
          header={config.infoMessage.header && config.infoMessage.header[language] ? config.infoMessage.header[language] : null}
          content={htmlToReactParser.parse(
            decodeUnicode(config.infoMessage.content[language])
          )}
          color={config.infoMessage.color ? config.infoMessage.color : 'green'} 
        />
      );
    }
    return null;
  }

  function renderFooter() {
    if (config && config.footer) {
      return (
        <Footer
          leftContent={htmlToReactParser.parse(
            decodeUnicode(config.footer.leftContent[language])
          )}
          rightContent={htmlToReactParser.parse(
            decodeUnicode(config.footer.rightContent[language])
          )}
        />
      );
    }
    return null;
  }

  return (
    <div className="App" style={{ height: "100vh" }}>
      <Navbar title={"Farma Forms"} image={"freddy.png"} />
      <Container
        style={{ marginBottom: 40, bottom: 20, top: 20, minHeight: "84vh" }}
      >
        {renderMessage()}

        {validationErrors && validationErrors.length ? (
          <ErrorList errors={validationErrors} />
        ) : null}

        {formHasLoaded ? (
          <div>
            <Card
              fluid
              style={
                formIsSubmitted
                  ? { top: 10, position: "relative", pointerEvents: "none" }
                  : { top: 10, position: "relative" }
              }
            >
              <Questionnaire />
            </Card>
            <div
              style={{
                position: "relative",
                top: 20,
                paddingLeft: "15",
                paddingRight: "15",
                marginBottom: 40,
              }}
            >
              <Button
                loading={loading}
                style={{ minWidth: 180, fontSize: "1.2em" }}
                color="green"
                icon="save"
                content={languageMap["save"][language]}
                labelPosition="right"
                onClick={handleSave}
                disabled={loading || formIsSubmitted}
              ></Button>
              <Button
                loading={loading}
                style={{
                  minWidth: 180,
                  fontSize: "1.2em",
                  float: "right",
                  marginRight: 0,
                }}
                color="blue"
                icon="send"
                content={languageMap["submit"][language]}
                labelPosition="right"
                onClick={handleSubmit}
                disabled={loading || formIsSubmitted}
              />
            </div>
          </div>
        ) : (
          <Loader
            size="large"
            style={{ top: "50%", position: "relative" }}
            inline="centered"
            active
          />
        )}

        <Actions actions={actions} />
      </Container>
      <div>{renderFooter()}</div>
    </div>
  );
}
//style={{ position: 'relative' ,marginTop: 'auto'}}
export default App;

import { useEffect, useState } from 'react';
import { useFhirClient } from 'react-fhirclient';

function useIsSessionAlive() {
    const client = useFhirClient();
    const [isAlive, setIsAlive] = useState();
    
    useEffect(() => {        
        if (client) {    
            console.log('client', JSON.stringify(client, null, 2));   
            const expiresAt =  client.state.expiresAt;
            const currentTimestamp = Math.floor(Date.now() / 1000);
            console.log(expiresAt, currentTimestamp);        
            
            if (currentTimestamp < expiresAt - 5) {
                setIsAlive(true);
            } else {
                setIsAlive(false);
            }
        }     
        
    }, [client]);
    
    return isAlive;
}

export default useIsSessionAlive;
import { Message } from 'semantic-ui-react';
import './MessageBox.css';

function MessageBox({ header, content, color }) {
    return (
        <Message color={color ? color : 'green'} floating className='message-box'>
            {header ? <Message.Header>{header}</Message.Header> : null}
            {content ? <Message.Content>{content}</Message.Content> : null}
        </Message>
    );
}

export default MessageBox;
import { useState } from 'react';

function useFFModal() {
    const [modalContent, setModalContent] = useState();
    const [modalHeader, setModalHeader] = useState();
    const [modalAction, setModalAction] = useState();
    const [modalType, setModalType] = useState();
    const [modalTitle, setModalTitle] = useState();
    const [modalMessage, setModalMessage] = useState();
    const [modalConfirmAction, setModalConfirmAction] = useState();
    const [modalCancelAction, setModalCancelAction] = useState();
    const [modalConfirmText, setModalConfirmText] = useState();
    const [modalCancelText, setModalCancelText] = useState();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [closeOnDimmerClick, setCloseOnDimmerClick] = useState(false);

    function openModal() {  
        setModalIsOpen(true);
      }
    
      function closeModal() {
        setModalHeader();
        setModalContent();
        setModalAction();
        setModalIsOpen(false);
      }
      
      function afterOpenModal() {

      }

      function showInfoModal({ title, message }) {
        setModalType('info');
        setModalTitle(title);
        setModalMessage(message);

        openModal();
    }

    function showConfirmModal({ title, message, confirm, cancel, confirmAction, cancelAction }) {
        setModalType('confirm');
        setModalTitle(title);
        setModalMessage(message);
        // Function that returns a function so that the it can be executed later with an onClick event for example, 
        // otherwise the function gets executed imediately.
        setModalConfirmAction(() => () => confirmAction());
        setModalCancelAction(() => () => cancelAction());
        setModalConfirmText(confirm);
        setModalCancelText(cancel);

        openModal();
    }

    function showErrorModal({ title, message }) {
        setModalType('error');
        setModalTitle(title);
        setModalMessage(message);

        openModal();
    }


    return {
        modalType,
        modalTitle, 
        modalMessage,
        modalContent,
        modalHeader,
        modalAction,
        modalConfirmAction,
        modalCancelAction,
        modalConfirmText,
        modalCancelText, 
        modalIsOpen,
        setModalType, 
        setModalContent,
        setModalHeader,
        setModalAction,
        setModalConfirmAction,
        setModalCancelAction,
        setModalIsOpen,
        openModal,
        closeModal,
        afterOpenModal,
        closeOnDimmerClick, 
        setCloseOnDimmerClick,
        showConfirmModal,
        showInfoModal,
        showErrorModal,
    }
}

export default useFFModal;
import { createContext } from "react";
import useFFModal from "../hooks/useFFModal";
import FFModal from "../components/FFModal/FFModal";

const ModalContext = createContext();

function ModalProvider({ children }) {
  const {
    modalType,
    modalTitle,
    modalMessage,
    modalContent,
    modalConfirmAction,
    modalCancelAction,
    modalConfirmText,
    modalCancelText,
    modalHeader,
    modalAction,
    modalIsOpen,
    setModalType,
    setModalTitle,
    setModalMessage,
    setModalContent,
    setModalHeader,
    setModalAction,
    setModalConfirmAction,
    setModalCancelAction,
    setModalIsOpen,
    openModal,
    closeModal,
    afterOpenModal,
    closeOnDimmerClick,
    setCloseOnDimmerClick,
    showConfirmModal,
    showInfoModal,
    showErrorModal,
  } = useFFModal();

  return (
    <ModalContext.Provider
      value={{
        modalType,
        modalTitle,
        modalMessage,
        modalContent,
        modalConfirmAction,
        modalCancelAction,
        modalConfirmText,
        modalCancelText,
        modalHeader,
        modalAction,
        modalIsOpen,
        setModalType,
        setModalTitle,
        setModalMessage,
        setModalContent,
        setModalHeader,
        setModalAction,
        setModalConfirmAction,
        setModalCancelAction,
        setModalIsOpen,
        openModal,
        closeModal,
        afterOpenModal,
        closeOnDimmerClick,
        setCloseOnDimmerClick,
        showConfirmModal,
        showInfoModal,
        showErrorModal,
      }}
    >
      <FFModal />
      {children}
    </ModalContext.Provider>
  );
}

export { ModalContext, ModalProvider };
